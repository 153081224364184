import React from "react";
import "./NotFound.scss";
import Desktop from "../../images/desktop_404_dot_bg.svg";
import Mobile from "../../images/mobile_404_dot_bg.svg";

const NotFound = () => {
  return (
    <div className="notfound">
      <Desktop className="desktop" />
      <div className="content">
        <span className="letters first">404</span>
        <span className="letters second">
          Something's <br /> missing
        </span>
        <a href="/" className="letters link">
          Go back
        </a>
      </div>
      <Mobile className="mobile" />
    </div>
  );
};

export default NotFound;
